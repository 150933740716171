export const face_icon  = require('assets/img/facebook.png')
export const phone_icon  = require('assets/img/phone-call.png')
export const email_icon  = require('assets/img/mail.png')
export const tiktok_icon  = require('assets/img/tik-tok.png')
export const youtube_icon  = require('assets/img/youtube.png')
export const down_icon  = require('assets/img/down-arrow.png')
export const logofooter  = require('assets/img/logofooter.png')
export const face_icon_1  = require('assets/img/facebook-1.png')
export const tiktok_icon_1  = require('assets/img/tiktok-1.png')
export const map_pin  = require('assets/img/map-pin.png')
export const youtube_icon_1  = require('assets/img/youtube-1.png')
export const phone_icon_1  = require('assets/img/phone-call-1.png')
export const email_icon_1  = require('assets/img/mail-1.png')
export const user_icon  = require('assets/img/user_icon.png')
export const anh_bia  = require('assets/img/anhbia.png')
export const arrow  = require('assets/img/arrow.png')
export const introduce  = require('assets/img/introduce.png')
export const introduce1  = require('assets/img/introduce1.png')
export const img_show  = require('assets/img/img-show.png')
export const img_donate  = require('assets/img/img-donate.png')
export const check_icon  = require('assets/img/check-circle.png')
export const heart_icon  = require('assets/img/heart.png')
export const gratidute  = require('assets/img/gratidute.png')
export const news_img  = require('assets/img/imgnew.png')
export const news_img1  = require('assets/img/imgnew1.png')
export const arrow_black  = require('assets/img/arrowblack.png')
export const calendar  = require('assets/img/calendar.png')
export const user_icon1  = require('assets/img/user.png')
export const lock_icon  = require('assets/img/lock.png')
export const anh_bia_page  = require('assets/img/anhbiapage.png')
export const img_gioithieu  = require('assets/img/img-gioithieu.png')
export const img_muctieu  = require('assets/img/muctieu.png')
export const icon_global  = require('assets/img/global.png')
export const icon_alert  = require('assets/img/alert-circle.png')
export const icon_book  = require('assets/img/book.png')
export const icon_clipboard  = require('assets/img/clipboard.png')
export const icon_edit  = require('assets/img/edit.png')
export const icon_log_out  = require('assets/img/log-out.png')
export const icon_server  = require('assets/img/server.png')
export const avatar  = require('assets/img/avatar.png')
export const avatar1  = require('assets/img/avatar1.png')



